import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <h5>Email</h5>
            <a className="navLink" href="mailto:tanew7391@gmail.com">tanew7391@gmail.com</a>
        </div>
    );
}
export default Footer;