"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Map of all Contentful marks.
 */
var MARKS;
(function (MARKS) {
    MARKS["BOLD"] = "bold";
    MARKS["ITALIC"] = "italic";
    MARKS["UNDERLINE"] = "underline";
    MARKS["CODE"] = "code";
})(MARKS || (MARKS = {}));
exports.default = MARKS;
